<template>
  <div class="bg-dark q-pt-sm q-px-md q-pb-md">
    <q-breadcrumbs class="text-white">
      <q-breadcrumbs-el
        v-for="(item, index) in crumbs"
        :key="index"
        v-bind="item"
        class="text-subtitle2"
      />
    </q-breadcrumbs>
  </div>
</template>

<script lang="ts" setup>
import type { QBreadcrumbsElProps } from 'quasar';
import { defineBreadcrumb } from 'nuxt-schema-org/schema';

const props = defineProps({
  items: {
    type: Array as PropType<QBreadcrumbsElProps[]>,
    required: true,
  },
});

const crumbs = computed(() => [{ label: 'Home', to: '/' }, ...props.items]);

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: crumbs.value.map(i => ({ name: i.label, item: i.to })),
  }),
]);
</script>

<style lang="scss" scoped>
@use "sass:map";

.description {
  @media (min-width: $breakpoint-md-min) {
    font-size: map.get($body1, 'size');
    font-weight: map.get($body1, 'weight');
    line-height: map.get($body1, 'line-height');
    letter-spacing: map.get($body1, 'letter-spacing');
  }
}
</style>
